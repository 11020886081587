<template>
  <div class="property-wrapper" v-if="control">
    <ControlBaseProperties v-model="control" />
    <ControlSpecificPanel :control="control">
      <DataStateListForm
        v-if="stateImages"
        v-model="dataSource"
        :equipmentData="equipmentData"
      />
    </ControlSpecificPanel>
  </div>
</template>
<script>
import StateControl from "@/components/synoptic/property-editor/controls/state-control.vue";
export default {
  name: "StatusLabel",
  extends: StateControl
};
</script>
